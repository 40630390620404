<template>
    <div class="details">
        <!-- Project Section -->
        <full-image :images="['canalcultura/canalcultura_1.jpg']"></full-image>
        <images3 :images="['canalcultura/canalcultura_2.png', 'canalcultura/canalcultura_3.png', 'canalcultura/cc_5.png']"></images3>
         <!------------------ABOUT------------------------------------------------->
        <div data-uk-scrollspy="cls:uk-animation-fade" class="padding-top-150px padding-bottom-150px">

            <div class="container">

            <!-- Row -->
            <div class="row justify-content-center" data-uk-scrollspy="cls: uk-animation-scale-up; target: > div; delay: 50; repeat: false">

                <!-- Column -->
                <div class="col-md-8 col-sm-12 col-xs-12 center-col text-center margin-auto">

                <h2 class="text-weight-100 text-gray-extra-dark margin-bottom-35px"><strong>Design da marca</strong> <br>Canal Cultura - Plataforma streaming de conteúdos culturais</h2>

                </div>
                <!-- End Column -->

            </div>
            <!-- End Row -->

            </div>
            <!-- End Container -->

        </div>
         <!------------------ABOUT------------------------------------------------->
        <images2 :images="['canalcultura/canalcultura_6.png', 'canalcultura/canalcultura_7.png']"></images2>
        <project-details project="Design de marca, design gráfico" client="Canal Cultura" date="2021" country="Angola"></project-details>
        
        <full-image :images="['canalcultura/cc_8.png']"></full-image>
        <!------------------DESC------------------------------------------------->
        <div data-uk-scrollspy="cls:uk-animation-fade" class="margin-bottom-150px margin-top-150px">
            <div class="container">
                <div class="row" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div; delay: 50; repeat: false">
                    <div class="col-md-12 col-sm-12 col-xs-12 center-col text-center">
                        <h5 class="text-weight-500 text-gray-extra-dark">A cor da cultura</h5>
                        <div class="width-100 sm-width-100">
                            <p class="text-gray-dark">Para o Canal Cultura a MYLOGO desenhou um logotipo repleto de símbolos. À primeira vista, identificamos os dois “C” de Canal Cultura unidos. No entanto, consegue-se identificar na mesma imagem, a representação de dois olhos, os do espectador e a representação do publico, com duas figuras sentadas, uma atrás da outra.<br>
Apesar de ser um logotipo repleto de significados, a sua forma é simples o que o torna fácil de lembrar, característica fundamental para a implementação com sucesso de um canal de streaming
A nível cromático optamos por um degrade que reforça a ideia de canal, de algo que flui.<br>
A paleta de cores utilizada vai dos laranjas ao roxo, cores fortes, de simbolismo muito ligado à cultura, com significados de paixão, excitação, criatividade, nobreza, sucesso.</p>
                        </div>
                    </div>
                </div>
            </div>
	    </div>
      <full-image :images="['canalcultura/canalcultura_9.jpg']"></full-image>
        <!------------------DESC------------------------------------------------->
        <div class="pager text-center margin-top-150px">
            <a href="/portfolio/bazza" class="btn btn-small border-radius-50 btn-black md-margin-bottom-15px margin-left-10px margin-right-10px pager-next btn btn-small md-margin-bottom-15px"> PROJETO Anterior</a>
            <a href="/portfolio/ingredient-odyssey" class="btn btn-small border-radius-50 btn-black md-margin-bottom-15px margin-left-10px margin-right-10px pager-next btn btn-small md-margin-bottom-15px">PROJETO Seguinte</a>
            <!-- <router-link :to="prevProject()" class="pager-next btn btn-small uk-button-text md-margin-bottom-15px sm-margin-left-right-auto sm-display-table">Anterior</router-link>
            <router-link :to="nextProject()" class="pager-next btn btn-small uk-button-text md-margin-bottom-15px sm-margin-left-right-auto sm-display-table">Próximo</router-link> -->
        </div>
      
        <footer-main-component></footer-main-component>
    </div>
</template>

<script>
import FooterMainComponent from '../FooterMainComponent.vue'
import Images2 from './types/2images.vue'
import Images3 from './types/3images.vue'
import ProjectDetails from './types/details.vue'
import FullImage from './types/fullimage.vue'


export default {
  name: 'Details',
  components: {
    FooterMainComponent,
    Images2,
    Images3,
    ProjectDetails,
    FullImage
  },
  metaInfo () {
    return {
	 title: 'Portfólio MYLOGO – Design da marca Canal Cultura - Plataforma streaming de conteúdos culturais',
      meta: [{
        vmid: 'description',
        name: 'description',
        content: 'Página de portfólio da MYLOGO. Conheça o trabalho que desenvolvemos em design de marca para a Canal Cultura - Plataforma streaming de conteúdos culturais.'
      }]
    }
  }
}
</script>
