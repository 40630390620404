<template>
    <div class="details">
        <!-- Project Section -->
        <full-image :images="['aiwama/aiwama_1.jpg']"></full-image>
         <!------------------ABOUT------------------------------------------------->
        <div data-uk-scrollspy="cls:uk-animation-fade" class="padding-top-150px padding-bottom-150px">

            <div class="container">

            <!-- Row -->
            <div class="row justify-content-center" data-uk-scrollspy="cls: uk-animation-scale-up; target: > div; delay: 50; repeat: false">

                <!-- Column -->
                <div class="col-md-8 col-sm-12 col-xs-12 center-col text-center margin-auto">

                <h2 class="text-weight-100 text-gray-extra-dark margin-bottom-35px"><strong>Design da marca</strong> Aiwama - Wine and food store</h2>

                </div>
                <!-- End Column -->

            </div>
            <!-- End Row -->

            </div>
            <!-- End Container -->

        </div>
         <!------------------ABOUT------------------------------------------------->
        <images2 :images="['aiwama/aiwama_4.jpg', 'aiwama/aiwama_5.jpg']"></images2>
        <project-details project="Design de marca, Packaging design, design gráfico" client="Aiwama" date="2013" country="Angola"></project-details>
        
        <full-image :images="['aiwama/aiwama_6.jpg']"></full-image>
        <full-image :images="['aiwama/aiwama_7.jpg']"></full-image>
        <!------------------DESC------------------------------------------------->
        <div data-uk-scrollspy="cls:uk-animation-fade" class="margin-bottom-150px margin-top-150px">
            <div class="container">
                <div class="row" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-up; target: > div; delay: 50; repeat: false">
                    <div class="col-md-12 col-sm-12 col-xs-12 center-col text-center">
                        <h5 class="text-weight-500 text-gray-extra-dark">Tradição com um toque de modernidade</h5>
                        <div class="width-100 sm-width-100">
                            <p class="text-gray-dark">A Aiwama, é um espaço dedicado ao vinho com serviços de consultoria vinícola, eventos, provas de vinho, espaço de petiscos e venda de acessórios.<br>
O padrão étnico utilizado no logotipo foi cuidadosamente escolhido para transmitir a ideia de artesanal e tradicional, além de dar um toque de sofisticação e singularidade. A escolha de cores é composta por tons terrosos, como o castanho, o bege e o verde que evocam as cores da terra e do vinho, além de remeter a uma sensação de aconchego e conforto.<br>
O design personalizado, a escolha cuidadosa de cores e o padrão étnico escolhido dão uma sensação de sofisticação, singularidade e versatilidade.<br>
O logotipo é capaz de representar não apenas a venda de vinhos, mas todos os serviços oferecidos pela empresa, mantendo sempre sua identidade única.</p>
                        </div>
                    </div>
                </div>
            </div>
	    </div>
        <!------------------DESC------------------------------------------------->
        <div class="pager text-center margin-top-150px">
            <a href="/portfolio/wellsafe" class="btn btn-small border-radius-50 btn-black md-margin-bottom-15px margin-left-10px margin-right-10px pager-next btn btn-small md-margin-bottom-15px"> PROJETO Anterior</a>
            <a href="/portfolio/patudos-e-barbatanas" class="btn btn-small border-radius-50 btn-black md-margin-bottom-15px margin-left-10px margin-right-10px pager-next btn btn-small md-margin-bottom-15px">PROJETO Seguinte</a>
            <!-- <router-link :to="prevProject()" class="pager-next btn btn-small uk-button-text md-margin-bottom-15px sm-margin-left-right-auto sm-display-table">Anterior</router-link>
            <router-link :to="nextProject()" class="pager-next btn btn-small uk-button-text md-margin-bottom-15px sm-margin-left-right-auto sm-display-table">Próximo</router-link> -->
        </div>
      
        <footer-main-component></footer-main-component>
    </div>
</template>

<script>
import FooterMainComponent from '../FooterMainComponent.vue'
import Images2 from './types/2images.vue'
import Images3 from './types/3images.vue'
import ProjectDetails from './types/details.vue'
import FullImage from './types/fullimage.vue'


export default {
  name: 'Details',
  components: {
    FooterMainComponent,
    Images2,
    Images3,
    ProjectDetails,
    FullImage
  },
  metaInfo () {
    return {
	 title: 'Portfólio MYLOGO – Design da marca da Aiwama',
      meta: [{
        vmid: 'description',
        name: 'description',
        content: 'Página de portfólio da MYLOGO. Conheça o trabalho que desenvolvemos em design de marca para a Wine anda food store, Aiwama .Tradição com um toque de modernidade.'
      }]
    }
  }
}
</script>
